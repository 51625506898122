/* Planda Meet */

.title {
    position: relative;
    /* width: 859px;
    height: 234px;
    left: 318px;
    top: 322px; */

    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 155px;

    color: #000000;
}

.button {
    position: relative;
    /* width: 372px;
    height: 100px; */
    /* left: 411px;
    top: 554px; */
    padding: 15px;

    background: #D9D9D9;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Create a Meeting */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 44px;
    /* identical to box height */
    color: #000000;
}

.button:hover {
    background: #BFBFBF;
}